import UnsupportedBrowser from "../UnsupportedBrowser"
import React from "react"
import Helmet from "react-helmet"
import { SessionS } from "."
import { useJungAudioSupport } from "../../../plugins/gatsby-plugin-jung-wrapper/useJungAudioSupport"
import SEO from "../SEO"
import SessionLayout from "../SessionLayout"
import { Session } from "../../types/freudTypes"
import { LoadingScreen } from "./loadingScreen"
import "./session.scss"
import { PrerenderedPlayer } from "./PrerenderedPlayer"

export const PrerenderedSessionPage = ({
    session,
    anonymousToken,
}: {
    session: SessionS
    anonymousToken: string
}): React.ReactElement => {
    if (!anonymousToken || session === "Loading") {
        return <LoadingScreen />
    }

    return <PrerenderedSessionPageMain location={location} session={session} />
}

export const PrerenderedSessionPageMain = ({ location, session }: { location: Location; session: Session }) => {
    const audioSupport = useJungAudioSupport()

    const shouldHaveAudioStream = session.startedAt

    return (
        <SessionLayout jungState={"blurDimmed"} isSessionActive={shouldHaveAudioStream}>
            <SEO title="Deep listening session" description="Wavepaths deep listening session." />
            <Helmet
                bodyAttributes={{
                    class: "session",
                }}
                key="helmet"
            />

            {shouldHaveAudioStream && audioSupport === "supported" && <PrerenderedPlayer session={session} />}
            {audioSupport === "unsupported" && <UnsupportedBrowser location={location} />}
        </SessionLayout>
    )
}
