import React, { useRef, useState } from "react"
import "./PrerenderedPlayer.scss"
import { animated, config, useSpring } from "react-spring"
import { useInterval } from "react-use"

import { Session } from "../../types/freudTypes"
import { SessionAudioPlayer, SessionAudioPlayerControls } from "./SessionAudioPlayer"
import SessionTimeline from "../SessionTimeline"

export function PrerenderedPlayer({ session }: { session: Session }): JSX.Element {
    const streamControlsRef = useRef<SessionAudioPlayerControls>(null)

    const [volume, setVolume] = useState(1)

    const [isPlaying, setIsPlaying] = useState(false)
    const [loading, setLoading] = useState(true)
    const [currentTime, setCurrentTime] = useState(0)

    const fadeIn = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 250,
        config: config.molasses,
    })

    useInterval(() => setCurrentTime(streamControlsRef.current?.getTime() ?? 0), 1000)

    const togglePlaying = () => {
        if (!streamControlsRef.current) return
        if (isPlaying) {
            streamControlsRef.current.pause()
        } else {
            streamControlsRef.current.play()
        }
    }

    return (
        <div className="prerenderedPlayerContainer">
            <div className="sessionAudioContainer">
                <SessionAudioPlayer
                    volume={volume}
                    broadcastIdentifier={session.broadcastIdentifier}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onLoad={() => setLoading(false)}
                    ref={streamControlsRef}
                    errorContext="Pregen Session"
                />
            </div>
            <main className="prerenderedContent">
                {!loading && (
                    <animated.div className="audioControls" style={fadeIn}>
                        <div className="playPauseButton" onClick={togglePlaying}>
                            {isPlaying ? (
                                <svg
                                    className="audioControlIcon"
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                </svg>
                            ) : (
                                <svg
                                    className="audioControlIcon"
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M14 38V10L36 24L14 38Z" />
                                </svg>
                            )}
                        </div>
                    </animated.div>
                )}
            </main>
            <SessionTimeline
                sessionTimeElapsed={currentTime * 1000}
                sessionTotalLength={session.duration}
                onChangeVolume={setVolume}
            />
        </div>
    )
}
